export function packOrders(orders) {
    // 按产品分组，计算总重量
    orders.forEach(order => {
        order.weight_per = order.weight_per / 1000;
      });
    console.log('order',orders)
    const productGroups = {};
    orders.forEach(order => {
      const productId = order.product_id;
      const totalWeight = order.quantity * order.weight_per;
      if (productGroups.hasOwnProperty(productId)) {
        productGroups[productId] += totalWeight;
      } else {
        productGroups[productId] = totalWeight;
      }
    });
  
    const boxes = [];
    const mixedRemainders = [];
  
    // 处理每个产品的装箱
    Object.entries(productGroups).forEach(([productId, totalWeight]) => {
      // 装满22kg的箱子
      const fullBoxesCount = Math.floor(totalWeight / 22);
      const remainderWeight = totalWeight % 22;
  
      // 添加满箱，每个箱子记录22kg
      for (let i = 0; i < fullBoxesCount; i++) {
        boxes.push({
          product_id: productId,
          weight: 22,
          items: [22]  // 假设每个产品单位重量相同，直接记录总重量
        });
      }
  
      // 处理剩余重量
      if (remainderWeight >= 12) {
        boxes.push({
          product_id: productId,
          weight: remainderWeight,
          items: [remainderWeight]
        });
      } else if (remainderWeight > 0) {
        mixedRemainders.push({
          product_id: productId,
          weight: remainderWeight
        });
      }
    });
  
    // 对混装余量按重量降序排列
    mixedRemainders.sort((a, b) => b.weight - a.weight);
  
    // 组合混装余量
    let currentBox = [];
    let currentWeight = 0;
    // 复制一份混装余量数组用于操作
    let remainingRemainders = mixedRemainders.slice();
  
    while (remainingRemainders.length > 0) {
      let bestFit = null;
      let bestFitIndex = -1;
      // 从大到小寻找可以加入当前箱子的最大余量（因为已排序，第一个符合条件的即为最佳）
      for (let i = 0; i < remainingRemainders.length; i++) {
        if (currentWeight + remainingRemainders[i].weight <= 22) {
          bestFit = remainingRemainders[i];
          bestFitIndex = i;
          break;
        }
      }
  
      if (bestFit) {
        currentBox.push(bestFit);
        currentWeight += bestFit.weight;
        // 移除已加入的余量
        remainingRemainders.splice(bestFitIndex, 1);
        // 检查当前箱子是否满足最低12kg要求
        if (currentWeight >= 12) {
          // 继续寻找更小的余量添加，以接近22kg
          for (let i = remainingRemainders.length - 1; i >= 0; i--) {
            if (currentWeight + remainingRemainders[i].weight <= 22) {
              currentBox.push(remainingRemainders[i]);
              currentWeight += remainingRemainders[i].weight;
              remainingRemainders.splice(i, 1);
            }
          }
          // 添加混装箱
          boxes.push({
            product_id: 'mixed',
            weight: currentWeight,
            items: currentBox
          });
          // 重置当前箱子
          currentBox = [];
          currentWeight = 0;
        }
      } else {
        // 无法找到合适的余量，如果当前箱子已有重量但未满足12kg要求，则抛出错误
        if (currentWeight > 0) {
          throw new Error(`无法满足混装箱子的重量要求，剩余重量：${currentWeight}kg`);
        }
        break;
      }
    }
  
    return boxes;
  }
  