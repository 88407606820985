<template>
  <a-card title="预分箱">
    <!-- 产品选择区 -->
    <div class="action-bar">
      <a-button type="primary" @click="addProduct">添加产品</a-button>
      <!-- 此处不再需要“生成分箱方案”按钮，结果自动更新 -->
    </div>

    <!-- 产品列表（包含合计行） -->
    <a-table
      :columns="productColumns"
      :dataSource="tableData"
      rowKey="id"
      :pagination="false"
    >
      <!-- 数量列 -->
      <template slot="quantity" slot-scope="value, record">
        <div v-if="record.id === 'summary'">
          {{ record.quantity }}
        </div>
        <div v-else>
          <a-input-number
            v-model="record.quantity"
            :min="1"
            style="width: 100px"
          />
        </div>
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="value, record">
        <div v-if="record.id === 'summary'"></div>
        <div v-else>
          <a-button-group size="small">
            <a-button type="danger" @click="removeProduct(record)">移除</a-button>
          </a-button-group>
        </div>
      </template>
    </a-table>

    <!-- 分箱方案预览区域 -->
    <div style="margin-top: 20px;">
      <h3>分箱方案预览</h3>
      <a-table
        :dataSource="packingResult"
        :rowKey="(record, index) => index"
        :pagination="false"
      >
        <a-table-column
          title="箱号"
          :customRender="(text, record, index) => index + 1"
        />
        <a-table-column
          title="箱类型"
          :customRender="(text, record) =>
            record.product_id === 'mixed' ? '混合箱' : '产品箱'"
        />
        <a-table-column title="总重量(kg)" dataIndex="weight" />
        <a-table-column title="箱内明细">
          <template slot-scope="value, record">
            <div v-if="record.product_id === 'mixed'">
              <div
                v-for="(item, idx) in record.items"
                :key="idx"
                style="margin-bottom: 4px;"
              >
                产品ID: {{ item.product_id }}, 重量: {{ item.weight.toFixed(2) }}kg
              </div>
            </div>
            <div v-else>
              重量: {{ record.items[0].toFixed(2) }}kg
            </div>
          </template>
        </a-table-column>
      </a-table>
    </div>

    <!-- 产品选择模态框 -->
    <materials-select-modal
      v-model="goodsSelectModalVisible"
      :warehouse="1"
      @select="onSelectProducts"
    ></materials-select-modal>
  </a-card>
</template>

<script>
import { packOrders } from "@/utils/packing";
export default {
  name: "PackingPreview",
  components: {
    MaterialsSelectModal: () =>
      import("@/components/GoodsSelectModal/index"),
  },
  data() {
    return {
      goodsSelectModalVisible: false,
      productColumns: [
        {
          title: "产品名称",
          dataIndex: "name",
          key: "name",
          // summary 行时直接显示文本
          customRender: (text, record) => (record.id === "summary" ? record.name : record.name),
        },
        {
          title: "单件重量(g)",
          dataIndex: "weight",
          key: "weight",
          // summary 行不显示单件重量
          customRender: (text, record) => (record.id === "summary" ? "" : record.weight),
        },
        { title: "数量", key: "quantity", scopedSlots: { customRender: "quantity" } },
        {
          title: "总重(g)",
          key: "totalWeight",
          // 非 summary 行：计算 单件重量 × 数量； summary 行直接显示预计算的合计总重
          customRender: (text, record) =>
            record.id === "summary" ? record.totalWeight : record.weight * record.quantity,
        },
        { title: "操作", key: "action", scopedSlots: { customRender: "action" } },
      ],
      // 已选产品数据，每项包含：id, name, weight, quantity
      selectedProducts: [],
    };
  },
  computed: {
    // 转换为 packOrders 需要的数据格式
    ordersForPacking() {
      return this.selectedProducts.map((product) => ({
        // product_id: product.id,
        product_id: product.name,
        quantity: product.quantity,
        weight_per: product.weight,
      }));
    },
    // 动态计算分箱结果
    packingResult() {
      try {
        return packOrders(this.ordersForPacking);
      } catch (error) {
        console.error("分箱计算错误:", error);
        return [];
      }
    },
    // 计算合计行（汇总所有产品的数量和总重）
    summary() {
      let totalQuantity = 0;
      let totalWeight = 0;
      this.selectedProducts.forEach((product) => {
        totalQuantity += Number(product.quantity) || 0;
        totalWeight += (Number(product.quantity) || 0) * (Number(product.weight) || 0);
      });
      return {
        id: "summary",
        name: "合计",
        quantity: totalQuantity,
        totalWeight: totalWeight,
      };
    },
    // 用于显示表格数据（在产品数据末尾追加 summary 行）
    tableData() {
      if (this.selectedProducts.length > 0) {
        return [...this.selectedProducts, this.summary];
      }
      return [];
    },
  },
  methods: {
    addProduct() {
      this.goodsSelectModalVisible = true;
    },
    onSelectProducts(item) {
      if (this.selectedProducts.some((p) => p.id === item.id)) {
        this.$message.warn("产品已存在");
        return;
      }
      // 假设 item 包含 id、name、gross_weight 等字段
      const newProduct = {
        id: item.id,
        name: item.name,
        weight: item.gross_weight,
        quantity: 1,
      };
      this.selectedProducts.push(newProduct);
      this.goodsSelectModalVisible = false;
    },
    removeProduct(record) {
      this.selectedProducts = this.selectedProducts.filter(
        (p) => p.id !== record.id
      );
    },
  },
};
</script>

<style scoped>
.action-bar {
  margin-bottom: 16px;
}
</style>
